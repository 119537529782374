import React from "react";
import type { AppProps } from "next/app";
import "../styles/globals.css";
import Layout from "../components/Layout";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import NProgress from "nprogress";
import "nprogress.min.css";

import { Router } from "next/router";
import { grey, pink } from "@mui/material/colors";
import DialogProvider from "../components/context/DialogContext";

const customTheme = createTheme({
  typography: {
    fontFamily: "Montserrat",
    allVariants: {
      fontFamily: "Montserrat",
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      main: grey[700],
    },
    secondary: {
      main: pink[500],
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        sx: {
          px: 1,
        },
        variant: "subtitle2",
        textTransform: "capitalize",
      },
    },
    MuiStack: {
      defaultProps: {
        sx: {
          px: 2,
          py: 1,
        },
        spacing: 2,
        direction: "row",
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiLink: {
      defaultProps: {
        sx: {
          color: (theme) => theme.palette.primary.main,
        },
        underline: "none",
      },
    },
    MuiButton: {
      defaultProps: {
        size: "small",
        // @ts-ignore
        p: 0,
        disableRipple: true,
      },
      variant: "text",
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  NProgress.configure({ showSpinner: false });

  Router.events.on("routeChangeStart", () => {
    NProgress.start();
  });

  Router.events.on("routeChangeComplete", () => {
    NProgress.done();
  });

  return (
    <ThemeProvider theme={customTheme}>
      <DialogProvider>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </DialogProvider>
    </ThemeProvider>
  );
}

export default MyApp;
