import React, { createContext } from 'react';

export const DialogContext = createContext<boolean>(false);

// @ts-ignore
export const DialogProvider = ({ children }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <DialogContext.Provider value={{
            open,
            setOpen
        }}>
            {children}
        </DialogContext.Provider>
    )
}

export default DialogProvider;
