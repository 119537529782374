import React, { ReactNode } from "react";
import Head from "next/head";
import dynamic from "next/dynamic";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { displayOnDesktop } from "../themes/commonStyles";

type LayoutProps = { children: ReactNode };

const Header = dynamic(() => import("../components/Header"));

const Footer = dynamic(() => import("../components/Footer"));

const FooterMenu = dynamic(() => import("../components/FooterMenu"));

const FooterLinks = dynamic(() => import("../components/FooterLinks"));

const ModalDialog = dynamic(() => import("../components/ModalDialog"));

export const metadata = {
  title: 'Vinivia – Creators & Advertisers',
  image: 'https://vinivia-live-27hgmt61j-webavenue.vercel.app/opengraph-image.png',
  description: 'Du möchtest deine Marke, deine Produkte und dich selbst innovativ vermarkten? Dann hol dir jetzt die App und werde Creator bei uns.',
  type: "image/jpeg",
  openGraph: {
    title: 'Vinivia – Creators & Advertisers',
    description: 'Du möchtest deine Marke, deine Produkte und dich selbst innovativ vermarkten? Dann hol dir jetzt die App und werde Creator bei uns.',
    url: 'https://vinivia.com/creators-advertisers',
    siteName: 'Vinivia.com'
  }
};

const Layout = ({ children }: LayoutProps) => {
  const calculateInnerWidth = () => {
    if (typeof window !== "undefined") {
      return window?.innerWidth > 600;
    }
  };

  return (
    <>
      <html lang="en">
        <Head>
          <title>Vacation Homes & Rentals</title>
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />

          <meta property="og:title" content={metadata.title} />
          <meta property="og:description" content={metadata.description} />
          <meta property="og:image" content={metadata.image} />
          <meta property="og:image:secure_url" content={metadata.image} />
          <meta property="og:type" content={metadata.type} />
          <meta property="og:image:alt" content={metadata.title} />

          <link
            rel="icon"
            type="image/x-icon"
            href="/icons/house-person-svgrepo-com.svg"
          />
        </Head>
        <body>
          <CssBaseline />
          {!!children?.props.properties || calculateInnerWidth() ? (
            <Header />
          ) : null}
          <main>{children}</main>
          <ModalDialog />
          <FooterLinks />
          <Box sx={displayOnDesktop}>
            <Footer />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              height: 100,
              overflowY: "scroll",
            }}
          >
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <FooterMenu property={children?.props} />
            </Box>
          </Box>
        </body>
      </html>
    </>
  );
};

export default Layout;
